<template>
  <div>
  123
  </div>
</template>

<script>
export default {
  name: '',
  props:[],
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  components: {},
  computed:{},
};
</script>

<style lang='scss' scoped>

</style>
